.order {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 27.5rem;
        gap: 2.5rem;
        @include r($md) {
            grid-template-columns: 1fr;
        }
    }
    &__col {
        &_offset {
            margin-top: -1.5rem;
        }
    }
    &-form {
        padding-top: 2.5rem;
        &__group {
            margin-bottom: 2.5rem;
            input, textarea, select, .choices {
                max-width: 36.25rem;
            }
            textarea {
                resize: none;
            }
            &-header {
                font-weight: 700;
                @include text16;
                margin-bottom: 2rem;
            }
            &-body {
                max-width: 42.5rem;
            }
        }
    }
    &-table {
        background: rgba(0,0,0,0.04);
        padding: 1.5rem;
        &_white {
            background: #fff;
            @include r($md) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        &__title {
            font-weight: 600;
            @include text22;
        }
        &__desc {
            font-weight: 400;
            @include text14;
            color: #6F6F6F;
            margin: 1.9rem 0 1.5rem;
        }
        &__cart {
            padding-top: 1.5rem;
            &-title {
                font-weight: 600;
                @include text22;
                margin-bottom: 2.25rem;
            }
            &-list {

            }
            &-line {
                display: flex;
                justify-content: space-between;
                max-width: 21.25rem;
                margin-bottom: 2rem;
                @include r($md) {
                    max-width: none;
                }
                span:nth-child(2) {
                    font-weight: 700;
                    @include text22;
                }
            }
        }
    }
}

.product-cart__remove {
    margin-top: 0.625rem;
    margin-left: 1.25rem;
    @include r($md) {
        margin-left: auto;
        margin-right: auto;
    }
    @include r($sm) {
        background: none;
        margin-right: 0;
        width: auto;
        &::after {
            content: 'Удалить';
            text-decoration: underline;
        }
    }
}

hr.hr {

}
