@include font('Montserrat', 'Montserrat-Regular', normal);
@include font('Montserrat', 'Montserrat-Medium', 500);
@include font('Montserrat', 'Montserrat-SemiBold', 600);
@include font('Montserrat', 'Montserrat-Bold', 700);
@include font('Montserrat', 'Montserrat-Black', 900);

$font-primary: 'Montserrat', sans-serif;

// only rem

@mixin text12 {
    font-size: .75rem;
    line-height: 130%;
}
@mixin text14 {
    font-size: .875rem;
    line-height: 130%;
}
@mixin text16 {
    font-size: 1rem;
    line-height: 140%;
}
@mixin text20 {
    font-size: 1.25rem;
    line-height: 130%;
}
@mixin text22 {
    font-size: 1.375rem;
    line-height: 130%;
}
@mixin text25 {
    font-size: 1.5625rem;
    line-height: 130%;
}
@mixin text35 {
    font-size: 2.1875rem;
    line-height: 133.1%;
    @include r($lg) {
        font-size: 2rem;
    }
    @include r($sm) {
        @include text25;
    }
}
@mixin text45 {
    font-size: 2.8125rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text35;
    }
}
@mixin text55 {
    font-size: 3.4375rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text45;
    }
}
@mixin text65 {
    font-size: 4.0625rem;
    line-height: 116.1%;
    @include r($sm) {
        @include text45;
    }
}

.content {
    color: #2B337F;
    padding: 2rem 0;
    @include r($sm) {
        @include text25;
        padding: 2rem 0 0;
    }
    h1 {
        font-weight: 700;
        @include text55;
        margin-bottom: 2.75rem;
        @include r($md) {
            @include text45;
        }
    }
    h2 {
        font-weight: 700;
        @include text45;
        margin-bottom: 2.25rem;
        @include r($md) {
            @include text35;
        }
    }
    p {
        @include text16;
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }
    ul, ol {
        @include text16;
        margin-bottom: 3rem;
        margin-left: 0.8rem;
        li {
            margin-bottom: 1rem;
        }
    }
    ul li {
        @include text16;
        &::before {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            display: inline-block;
            border-radius: 50%;
            background: rgba(0, 73, 139, 1);
            margin-right: 2.5rem;
        }
    }
}
