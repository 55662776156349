.hero-with-product {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 20.3125rem;
        gap: 1.25rem;
        @include r($xs) {
            grid-template-columns: 1fr;
        }
    }
}

.hero__product {
    .product-item {
        padding-right: 0;
    }
}
