.manufacturer-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1.25rem;
    @include r($md) {
        grid-template-columns: repeat(4, 1fr);
    }
    @include r($sm) {
        grid-template-columns: repeat(2,1fr);
    }
    &__item {
        position: relative;
        padding: 1rem;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.04);
        }
        img {
            width: 100%;
            max-height: 6.25rem;
            object-fit: contain;
            transition: transform .3s ease;
        }
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}
