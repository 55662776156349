.product-card {
    &__hero {
        display: grid;
        grid-template-columns: 1.05fr 1fr;
        gap: 5.4rem;
        padding-right: 0;
        margin-bottom: 10rem;
        @include r($md) {
            grid-template-columns: 1fr;
        }
        @include r($sm) {
            margin-bottom: 2.5rem;
            gap: 0;
        }
        &-gallery {
            display: grid;
            grid-template-columns: 3.1875rem 1fr;
            gap: 3rem;
            @include r($lg) {
                gap: 2rem;
            }
            @include r($md) {
                gap: 2rem;
                grid-template-columns: 0.25fr 1.5fr;
            }
            @include r($sm) {
                display: flex;
                flex-direction: column-reverse;
                flex-wrap: wrap;
                gap: 0;
            }
        }
    }
    &__info {
        padding-top: 0.75rem;

    }
    &__data {
        &-brand {
            color: $primary;
            text-decoration: underline;
        }
        &-group {
            margin-bottom: 1rem;

        }
    }
    &__favorite {
        width: 2rem;
        height: 2rem;
        background: transparent;
        transition: opacity .15s ease;
        svg {
            width: 100%;
            height: 100%;
            fill: transparent;
            stroke: #000;
            stroke-width: 2px;
            transition: fill .3s ease;
        }
        &:hover {
            svg {
                fill: rgba(0,0,0,.6);
            }
        }
        &.added {
            svg {
                fill: #000;
            }
        }
    }
    &__buy {
        &-cost {
            font-weight: 700;
            @include text45;
            margin-bottom: 0.5rem;
            display: flex;
            align-items: flex-end;
            gap: 1.5625rem;
            i {
                font-style: normal;
            }
            &_old {
                font-weight: 600;
                @include text14;
                text-decoration-line: line-through;
                color: #B9B9B9;

            }
            &_new {
                color: #FF7A00;
            }
            &_current {

            }
        }
        &-order {
            display: grid;
            // grid-template-columns: 3.75rem 11.25rem 2rem;
            grid-template-columns:  11.25rem 2rem;
            gap: 2rem;
            margin: 1.5rem 0;
            align-items: center;
        }
    }
    &__quick-buy {
        font-weight: 600;
        @include text14;
        color: #1B8DF4;
        background: transparent;
        &:hover {
            text-decoration: underline;
        }
    }

    &__order {
        &-product {
            margin: 2.5rem 0 0;
        }
        &-test {
            padding: 1.5rem 0;
            display: block;
        }
    }
}

.product-image {
    &__thumbs {
        display: flex;
        flex-direction: column;
        gap: 2.1rem;
        @include r($lg) {
            gap: 1rem;
        }
        @include r($sm) {
            flex-direction: row;
            overflow-x: auto;
            width: calc(100vw - var(--bs-gutter-x, 0.75rem) * 2);
            padding: 15px 0;

        }

        &-item {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            height: 3.1875rem;
            @include r($md) {
                height: 6.5rem;
            }
            @include r($sm) {
                width: 53px;
                min-width: 53px;
                height: 53px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: #0270B9;
                opacity: 0;
                z-index: 1;
                transition: opacity .15s ease;
            }
            &.active {
                &::before {
                    opacity: 0.5;
                }
            }

        }
    }
    &__main-image {
        width: 100%;
        border-radius: 1rem;
        overflow: hidden;
        padding: 2rem;
    }
    &__main {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(44, 154, 255, 0.04);
            border-radius: 27px;
        }
    }
}

.product-params {
    margin-top: 2rem;
    &__title {
        font-style: normal;
        font-weight: 700;
        @include text14;
        margin-bottom: 1.25rem;
    }
    &__list {
        max-width: 15rem;
        @include r($sm) {
            max-width: 100%;
        }
    }
    &__item {
        font-weight: 500;
        @include text14;
        margin-bottom: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dotted #2C9AFF;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        span {
            background: #fff;
            margin-bottom: -1px;
            padding: 0 0.5rem;
            min-width: 15rem;
            @include r($sm) {
                min-width: auto;
            }
        }
    }
}

.product-info {
    &__category {
        display: flex;
        margin-bottom: 2.5rem;
        gap: 2.5rem;
        @include r($sm) {
            display: none;
        }
        &-link {
            font-weight: 500;
            @include text16;
        }
    }
}

.product-info {
    &__group {
        margin-bottom: 2.5rem;
        &-name {
            font-weight: 700;
            @include text45;
            margin-bottom: 1.5rem;
        }

    }


}
