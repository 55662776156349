.product-item {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    height: 100%;
    @include r($sm) {
        padding: 0;
    }
    &__figure {
        display: block;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        margin-bottom: 0.6rem;
        @include r($sm) {
            margin-left: 0;
            margin-right: 0;
        }
        &::after {
            content: '';
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            background: rgba(44, 154, 255, 0.04);
            padding-bottom: 100%;
            border-radius: 1.6875rem;
            pointer-events: none;
        }
    }
    &__image {
        position: absolute;
        left: 50%;
        top: 50%;
        object-fit: contain;
        transform: translate(-50%, -50%);
        width: calc(100% - 1.5625rem * 2);
        height: calc(100% - 1.5625rem * 2);

    }

    &__code, &__name {
        font-weight: 400;
        @include text14;
        margin: 0.25rem 0;
    }
    &__code {
        color: #8A8A8A;
    }
    &:hover {
        .product-item__favorite {
            opacity: 1;
        }
    }
    &__favorite {
        position: absolute;
        right: 1.25rem;
        bottom: 1.25rem;
        width: 2rem;
        height: 2rem;
        background: transparent;
        opacity: 0;
        transition: opacity .15s ease;
        svg {
            width: 100%;
            height: 100%;
            fill: transparent;
            stroke: #000;
            stroke-width: 2px;
            transition: fill .3s ease;
        }
        &:hover {
            svg {
                fill: rgba(0,0,0,.6);
            }
        }
        &.added {
            svg {
                fill: #000;
            }
        }
    }
    &__options {
        margin: 0.6rem 0;
        display: flex;
        justify-content: space-between;
        @include r($xl) {
            gap: 2rem;
        }
    }
    &__price {
        font-weight: 600;
        @include text35;
        color: #00003E;
        i {
            @include text16;
            font-style: normal;
            font-weight: 600;
            margin-left: 3px;
        }
        &_new {
            font-weight: 800;
            color: $orange;
        }
        &_old {
            @include text16;
            text-decoration-line: line-through;
            color: #B9B9B9;
        }
    }
    &__footer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    &__btn {
        margin-top: auto;
    }
    // view row
    &_row {
        display: grid;
        gap: 2rem;
        grid-template-columns: 9.375rem 1fr 1.25fr;
        @include r($md) {
            grid-template-columns: 9.375rem 1fr 1fr;
        }
        @include r($sm) {
            display: flex;
            flex-wrap: wrap;
            gap: 10px 20px;
            flex-direction: row;
            .product-item__figure {
                width: 80px;
                height: 80px;
            }
            .product-item__body {
                width: calc(100% - 80px - 20px)
            }
            .product-item__options {
                align-items: center;
            }
        }
        .product-item__favorite {
            width: 1.5rem;
            height: 1.5rem;
            bottom: 1rem;
            right: 1rem;
        }
        .product-item__options {
            margin: 0;
            @include r($sm) {
                padding-left: 100px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
        .product-labels {
            margin: 0.25rem 0 0.5rem;
            min-height: 1.8rem;
        }
        .product-item__footer {
            display: grid;
            grid-template-columns: 1fr 0.8fr;
            align-items: flex-start;
            gap: 2.5rem;
            margin-top: 4rem;
            @include r($sm) {
                margin-top: 0.5rem;
                align-items: center;
                gap: 10px;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 30px;
            }
        }
        .product-item__price {
            margin-top: -0.25rem;
        }
        .product-item__btn {
            margin-top: 0;
            @include r($sm) {
                width: 100%;
            }
        }
    }
}

.product-labels {
    &_absolute {
        position: absolute;
        left: -3px;
        bottom: 0.75rem;
        z-index: 1;
        flex-direction: column;
        align-items: flex-start;
    }
    display: flex;
    gap: 0.625rem;
    &__item {
        font-weight: 500;
        @include text12;
        display: inline-flex;
        align-items: center;
        color: #FFFFFF;
        background: #777;
        padding: 0.55rem 0.75rem;
        border-radius: 0.3125rem;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            transition: background .2s ease;
        }
        &:hover {
          color: #FFFFFF;
          &::after {
              background: rgba(255,255,255,0.25);
          }
        }
        &_new {
            background: $label-new-blue;
        }
        &_sale {
            background: $orange;
        }

    }
}
