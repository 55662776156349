.hero__slider {
    --swiper-theme-color: rgba(255,255,255,0.5);
    width: 100%;
    overflow: hidden;
    position: relative;
    .swiper-slide {
        min-height: 33.125rem;
        @include r($sm) {
            min-height: 12.125rem;
        }
    }
    img {
        width: 100%;
        height: 33.125rem;
        object-fit: cover;
        @include r($sm) {
            height: 12.125rem;
        }
    }

}

.swiper-button-prev {
    left: 1.25rem;
}
.swiper-button-next {
    right: 1.25rem;
}

@include r($lg) {
    :root {
        --swiper-navigation-size: 2.5rem;
    }
}
