.footer {
    margin-top: 4.7rem;
    &-top {
        padding: 1.1875rem 0;
        background: $footer-top-background;
        color: #fff;
        @include r($sm) {
            display: none;
        }
    }
    &-nav {
        &__list {
            margin: 2.1875rem 0;
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            gap: 3.75rem;
            @include r($md) {
                grid-template-columns: repeat(3, 1fr);
                gap: 1.5rem;
            }
            a {
                color: #fff;
                transition: opacity .25s ease;
                &:hover {
                    opacity: 0.5;
                }
            }
        }
        &__group {
            &:first-child {
                .footer-nav__group-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 0 3.75rem;
                    @include r($md) {
                        grid-template-columns: 1fr;
                        gap: 0 1.5rem;
                    }
                }
            }
            &-title {
                @include text22;
                font-weight: 600;
                margin-bottom: 1.75rem;
                display: block;
            }
            &-link {
                @include text16;
                display: block;
                margin-bottom: 1.5rem;
            }
        }
    }
    &-bottom {
        background: $footer-bottom-background;
        padding: 1.6875rem 0;
        &__grid {
            display: grid;
            grid-template-columns: 1fr 3fr;
            @include r($sm) {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    &-logo img {
        width: 100%;
        height: 100%;
        max-width: 11.125rem;
        max-height: 3.75rem;
        object-fit: contain;
        @include r($sm) {
            display: block;
            margin-bottom: 2rem;
        }
    }
    &-contacts {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.875rem;
        @include text16;
        @include r($md) {
            gap: 1rem;
            flex-wrap: wrap;
        }
        a {
            color: #fff;
        }
        &__address {
            max-width: 18.75rem;
            @include r($xl) {
                max-width: 15rem;
            }
            @include r($md) {
                max-width: 100%;
                width: 100%;
            }
        }
        &__phone {
            font-weight: 600;
        }
        &__email {
            font-weight: 600;
            border-bottom: 1px solid #fff;
            transition: border-color .15s ease;
            &:hover {
                border-color: transparent;
            }
        }
        &__social {
            display: flex;
            flex-wrap: wrap;
            gap: 0.625rem;
            align-items: center;
            justify-content: flex-end;
            &-item {
                display: inline-block;
                img {
                    max-width: 2rem;
                    max-height: 2rem;
                    object-fit: contain;
                }
            }
        }
    }
}
