.sidebar-filter {
    margin: 1.4rem 0;
    width: 100%;
    &__group {
        margin-bottom: 1.5rem;
    }

    &__label {
        font-weight: 700;
        @include text16;
        margin-bottom: 1.25rem;
    }
}
