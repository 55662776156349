.hero-block {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    padding: 6.5rem 7.75rem 4rem;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        background: rgba(0,0,0,0.25);
    }
    @include r($md) {
        padding: 3.5rem 3.75rem 2rem;
    }
    @include r($sm) {
        padding: 2rem 1rem 2rem;
    }
    &__title {
        color: #FFFFFF;
        font-weight: 700;
        @include text65;
        margin: 1.75rem 0;
        text-transform: uppercase;
        @include r($sm) {
            margin-top: 0;
            @include text35;
        }
    }
    &__description {
        color: #FFFFFF;
        margin: 1.75rem 0;
        @include text25;
        @include r($sm) {
            @include text20;
            margin-bottom: 0.5rem;
        }
    }
}
