html,
:root {
  font-size: 12px;
  transition: font-size .15s ease;
  @include rmin(768) {
    font-size: 12px;
  }
  @include rmin(992) {
    font-size: 12px;
  }
	@include rmin(1199) {
    font-size: 14px;
  }
	@include rmin(1300) {
		font-size: 15px;
  }
// 	@include rmin(1360) {
// 		font-size: 16px;
//   }
// 	@include rmin(1700) {
// 		font-size: 21px;
//   }
// 	@include rmin(2300) {
//     font-size: 28px;
//   }
}

html, body {
	height: 100%
}
body {
	background: #fff;
	@include text16;
	font-family: $font-primary;
	display: flex;
	flex-direction: column;
	line-height: 140%;
	-webkit-font-smoothing: antialiased;
    color: #00003E;
	// responsive
	// +css-lock(16, 50, 600, 1000)
}

.main {
    flex-grow: 1;
}

.box-widget {
    padding: 2.5rem 0;
    @include r($md) {
        padding: 1.75rem 0;
    }
    &:first-child {
        padding-top: 1.25rem;
    }
    .container > * {
        margin: 1rem 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 0.5rem;
    }
    &__title {
        font-weight: 600;
        @include text45;
        color: #00003E;
        margin-bottom: 1.25rem;
    }
    &__link {
        font-weight: 400;
        @include text20;
        display: inline-block;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid $primary;
        color: $primary;
        transition: border-color .15s ease;
        &:hover {
            border-color: transparent;
        }
    }

    &__body {
    }

    &__footer {

    }

}

.quantity-counter {
    display: flex;
	user-select: none;
    gap: 2px;
    color: #00003E;
    text-align: center;
    &__input input {
        width: 2.625rem;
        height: 2.625rem;
        padding: 0.25rem;
        background: #F5F9FC;
        border-radius: 1px;
        font-weight: 600;
        @include text16;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: background .2s ease;
        &:hover {
            background: darken(#F5F9FC, 3%);
        }
    }
    &__controls {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    &__button {
        padding: 0.25rem;
        width: 1.25rem;
        height: 1.25rem;
        background: #F5F9FC;
        border-radius: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        @include text12;
        cursor: pointer;
        transition: background .2s ease, color .2s ease;
        &:hover {
            background: $dark;
            color: #FFFFFF;
        }
    }
}
//
.btn {
    &-primary {
        background: #1B8DF4;
        border-color: #1B8DF4;
    }
    &-dark {
        transition: background .2s ease;
        &:hover {
            background: lighten($dark, 15%);
        }
    }
}


.dropdown-menu {
    box-shadow: 0 0.25rem 2.5rem rgba(0, 0, 0, 0.15);
    overflow: hidden;
    min-width: 17.1875rem;
    li:not(:last-child) .dropdown-item {
        border-bottom: 1px solid #F0F0F0;
        &.active {
            border-bottom: 1px solid #006ba8 !important;
        }
        &:hover {
            border-bottom: 1px solid #f0f0f0;
        }
    }
}

.form-check {
    display: flex;
    align-items: center;
    font-weight: 500;
    @include text14;
    color: #00003E;
    &-input {
        margin-top: 0;
        margin-right: 0.5rem;
        &:checked[type=checkbox] {
            background-size: 0.75rem;
        }
    }
    &-label {
        margin-left: 0.6rem;
    }

}

// tabs
.tabs {
    &__list {
        display: flex;
        // flex-wrap: nowrap;
        flex-wrap: wrap;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__link {
        padding: 0.625rem 1.675rem;
        display: block;
        // @include text20;
        text-align: center;
        word-break: break-all;
        color: $primary;
        margin-bottom: 1rem;

        border-radius: 2.19rem;
        border: 1px solid $primary;
        background: #fff;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:hover {
            color: $black;
        }

        &.active {
            background-color: $primary;
            border: none;
            color: #fff;
        }
    }
}

.modal {
    &-auth {
        .modal-body {
            padding-left: 5rem;
            padding-right: 5rem;
        }
        &__title {
            font-weight: 700;
            @include text35;
            margin-bottom: 1.25rem;
            text-align: center;
        }
        &-link {
            font-weight: 400;
            @include text14;
            color: #545454;
            margin: 0.75rem auto;
            a, button {
                font-weight: 400;
                @include text14;
                background: transparent;
                &:not(:hover) {
                    color: #545454;
                }

            }
        }
        &-forgot {
            margin: 1.5rem auto 0;
            text-align: center;
            display: block;
            font-weight: 400;
            @include text14;
            text-decoration-line: underline;
            color: #000000;

        }
    }
}

.badge {
    font-weight: 500;
    @include text12;
    padding: 0.375rem 0.625rem;
    &_active {
        background: #1ED300;
    }
}

.form-check-input[type="radio"] {
    &:checked {
        background-color: #00003E !important;
        border-color: #00003E !important;
    }
}
.form-check-input[type="radio"] + label {
    @include text16;
    color: #00003E;
    span {
        display: block;
        font-size: 10px;
        line-height: 170.1%;
        color: #00003E;
    }
}

.choices__inner {
    background: #FFFFFF;
    border: 1px solid #ced4da;
    // border: 1px solid #AAC0D7;
    @include text16;
    border-radius: 5px !important;
    padding: 0.46875rem 1.25rem 0.234375rem;
    display: flex;
    align-items: center;
}

.choices__list--dropdown {
    box-shadow: 0px 4px 40px rgba(0,0,0, 0.15);
    border-radius: 5px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border: 0 !important;
}
.choices__list--dropdown .choices__item {
    color: #006BA8;
    border-bottom: 1px solid #F0F0F0;
    padding: 0.625rem 1.5rem !important;
}

.form-select-lg + .choices__list {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    @include text16;
    padding-left: 0;
}

.container .container {
    padding-left: 0;
    padding-right: 0;
}

body:not(.ready) .mmenu__list {
    display: none !important;
}

.btn-warning {
    color: #fff;
}
