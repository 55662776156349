$xl: 1359;
$lg: 1199;
$md: 991;
$sm: 767;
$xs: 575;

//variables
$dark: #2B337F !default;
$orange: #F98913 !default;



// blocks
$header-top-background: #00005A;
$header-top-color: #fff;

$label-new-blue: #001AFF !default;

$footer-top-background: #00005A;
$footer-bottom-background: #00003E;
