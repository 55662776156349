.category-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;
    @include text16;
    font-weight: 500;
    @include r($md) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include r($sm) {
        grid-template-columns: repeat(2,1fr);
        gap: 0.75rem;
    }
    &_sm {
        @include text14;
        @include r($md) {
            grid-template-columns: repeat(4,1fr);
        }
        @include r($sm) {
            grid-template-columns: repeat(2,1fr);
        }
    }
    &__item {
        position: relative;
        border-radius: 0.8125rem;
        overflow: hidden;
        &::after {
            content: '';
            padding-bottom: 100%;
            background: rgba(0,0,0,0.04);
            display: block;
            width: 100%;
            z-index: 1;
            position: relative;
            transition: background .5s ease;
        }
        &:hover {
            &::after {
                background: rgba(0, 127, 243, 0.02);
            }
        }
        &-content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 2.5rem 2rem 3rem;
            text-align: center;
            img {
                width: 100%;
                height: calc(100% - 2rem);
                object-fit: contain;
                margin-bottom: 1.25rem;
            }
        }
    }
}
