.contacts-info {
    padding-top: 2.75rem;
    padding-bottom: 2rem;

    a {
        display: block;
        color: $black;
    }

    &__content {
        display: flex;
        gap: 2.5rem;
        @include r($sm) {
            flex-wrap: wrap;
        }
    }

    &__list {
        max-width: 32.56rem;
        padding-left: 0;
        padding-top: 0.5rem;
    }

    &__social-box {
        display: flex;
    }

    &__item {

        &-wrap {
            padding: 1rem 0;
            display: block;

            &_hover {
                position: relative;
                &:hover {
                    &::before {
                        content: "";
                        z-index: -1;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 109%;
                        height: 100%;
                        transform: translate(-50%, -50%);
                        background: rgba(#F2F2F2, .5);
                        border-radius: 1rem;
                        transition: opacity 0.2s ease;
                    }
                }
            }
        }
        &-link {
            color: $blue !important;
            font-weight: 600;
            @include text20;

            &_tel {
                margin-right: 0.69rem;
            }
        }

        &-box {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
        }

        &-title {
            font-weight: 600;
            @include text20;

            &_city {
                margin-bottom: 0.25rem;
                font-weight: 700;
                @include text35;
                color: #8A58A5;
                margin-bottom: 0.75rem;
            }
        }

        span {
            display: block;
            font-weight: 500;
            @include text16;
        }

    }

    &__map {
        width: 100%;

        #map {
            border-radius: 1rem;
            overflow: hidden;
            min-height: 21.875rem;
            height: 100%;
        }
    }
}

.social-item {
    display: block;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
    svg {
        width: 100%;
        height: 100%;
        fill: $purple;
    }
}
