.cabinet {
    &__content {
        margin: 2rem 0;
        @include r($sm) {
            margin-top: 0;
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: 12.5rem 1fr;
        gap: 1.25rem;
        @include r($sm) {
            grid-template-columns: 1fr;
        }
    }
    &__sidebar {
        font-weight: 400;
        @include text16;
    }
    &__nav {
        padding-top: 0.7rem;
        &-item {
            display: block;
            color: rgba(84, 84, 84, 1);
            margin-bottom: 1.5rem;
            &.active {
                color: #000;
            }
        }
    }
    &__exit-link {
        margin-top: 10.625rem;
        display: block;
        font-weight: 700;
        @include text16;
        @include r($sm) {
            margin-top: 1rem;
        }
    }
    &__main {

    }
    &-card {
        margin-bottom: 1rem;
        border: 1px solid #CDCDCD;
        &_gray {
            background: rgba(0, 0, 0, 0.02);
        }
        &__header {
            font-weight: 700;
            @include text16;
            color: #000000;
            border-bottom: 1px solid #CDCDCD;
            padding: 1.4rem 2rem 1rem;
        }
        &__body {
            padding: 1.4rem 2rem 1rem;
        }
        &__footer {
            padding: 1.4rem 2rem 1rem;

            border-top: 1px solid #CDCDCD;
        }
    }
    &-history {
        &__list {
            font-weight: 400;
            @include text16;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 1rem;
            color: #545454;
        }
        &__item {
            display: grid;
            grid-template-columns: 10.75rem 1fr;
            gap: 1.25rem;
            margin-bottom: 1.625rem;
            span:nth-child(2) {
                font-weight: 400;
                @include text16;
                color: #000000;
            }
        }
        &__more {
            font-weight: 700;
            @include text16;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: transparent;
            width: 100%;
            svg {
                width: 1rem;
                height: 0.6rem;
                transform: rotate(-180deg);
                transition: transform .3s ease;
            }
            &.collapsed {
                svg {
                    transform: rotate(0deg);
                }
            }
        }
        &__order {
            &-image {
                width: 5.875rem;
                min-width: 5.875rem;
                height: 5.875rem;
                object-fit: contain;
                margin-bottom: 1rem;
            }
            &-list {
                padding: 0;
            }
            &-item {
                border-top: 1px solid #CDCDCD;
                background: #fff;
                padding: 1.4rem 2rem 1rem;
                display: grid;
                grid-template-columns: 5.9375rem 1fr;
                gap: 1.25rem 6.5625rem;
                @include r($sm) {
                    grid-template-columns: 5.9375rem 1fr;
                    gap: 1.25rem 1.5625rem;
                }
            }
            &-title {
                max-width: 28.125rem;
                margin-bottom: 1.75rem;
            }
            &-params {
                font-weight: 400;
                @include text16;
                color: #545454;
            }
            &-line {
                margin-bottom: 1rem;
                display: flex;
                justify-content: space-between;
                max-width: 15.625rem;
                span:nth-child(2) {
                    font-weight: 400;
                    @include text16;
                    color: #000000;
                    min-width: 5.625rem;
                }
            }
        }

    }
}
