.breadcrumb {
    font-weight: 500;
    @include text14;
    text-transform: lowercase;
    a {
        color: $primary;
        border-bottom: 1px solid transparent;
        transition: border-color .2s ease;
        &:hover {
            border-color: $primary;
        }
    }
}
