.header {
    //top and elements
    @include r($md) {
        display: none;
    }
    @include text14;
    &-top {
        padding: 1.5rem 0;
        display: grid;
        grid-template-columns: 16.2% 56% 1fr;
        gap: 1rem;
        align-items: center;
        @include text14;
        &__wrapper {
            background: $header-top-background;
            color: $header-top-color;
            margin-bottom: 0.75rem;
        }
    }

    &-logo {
        &__link {
            // margin-left: 0.75rem;

        }
        img {
            width: 100%;
            height: 100%;
            max-width: 11.125rem;
            max-height: 3.75rem;
            object-fit: contain;
        }
    }

    &-navbar {
        display: flex;
        justify-content: space-between;
        gap: 3rem;
        @include r($xl) {
            gap: 2rem;
        }
        &__link {
            color: $header-top-color;
            &:hover {
                opacity: 0.7;
                color: $header-top-color;
            }
        }
    }

    &-contacts {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 3rem;
    }

    &-city {
        &__toggle {
            color: var(--bs-orange);
            background: none;
            text-decoration: underline;
            @include text14;
            &:hover {
                color: lighteen(var(--bs-orange), 3%)
            }

        }
        .dropdown-menu {
            top: 1rem !important;
            left: -1.75rem !important;
        }
    }

    &-phone {
        font-weight: normal;
        color: var(--bs-orange);
        &:hover {
            color: lighteen(var(--bs-orange), 3%)
        }
    }

    // middle and elements
    &-middle {
        padding: 0.2rem 0 1rem;
        display: grid;
        grid-template-columns: 16.2% 1fr auto;
        gap: 1rem;
        align-items: center;
    }

    &-catalog {
        &__toggle {
            display: inline-flex;
            align-items: center;
            position: relative;
            text-align: center;
            justify-content: center;
            width: 100%;
            padding: 0.5rem;
            font-weight: bold;
            svg {
                width: 1.625rem;
                height: 1.625rem;
                margin-right: 2rem;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover {
                svg {
                    fill: var(--bs-primary);
                }
            }
        }
    }

    &-search {
        position: relative;
        &__input {
            @include text14;
            padding: 0.75rem 1.75rem;
            border-radius: 1rem;
            box-shadow: none !important;

        }
    }

    &-controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 2rem;
        gap: 4.5rem;
        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            line-height: 0.875rem;
            font-weight: bold;
            svg {
                width: 1.875rem;
                height: 1.875rem;
                margin-right: 1rem;
                @include r($sm) {
                    margin-right: 0;
                }
            }
            &:hover {
                .header-controls__item_counter {
                    background: var(--bs-primary);
                }
                svg {
                    fill: var(--bs-primary);
                }
            }
            &_favorite {

            }

            &_cabinet {
                svg {
                    width: 1.75rem;
                    height: 1.75rem;
                }
            }

            &_cart {
            }

            &_counter {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                @include text14;
                width: 2rem;
                height: 2rem;
                background: #000;
                border-radius: 50%;
                margin-left: 1rem;
                overflow: hidden;
                color: #fff;
                @include r($sm) {
                    margin-left: -0.5rem;
                }
            }
        }


    }

    // bottom and elements
    &-bottom {
        padding: 0.2rem 0 1rem;
        display: grid;
        grid-template-columns: 16.2% 1fr;
        gap: 1rem;
        align-items: center;
    }

    &_short {
        .header-top {
            grid-template-columns: 56% 1fr;
        }

    }

}

.offcanvas {
    &-catalog {
        width: 100%;
        max-width: 85.625rem;
        .offcanvas-body, .offcanvas-header {
            padding: 1.375rem 3.0625rem;
        }
        .offcanvas-header {
            display: grid;
            grid-template-columns: 1.6fr 3fr;
            &__controls {
                display: flex;
                justify-content: space-between;
                .header-search__input {
                    border-radius: 0;
                    border-left: 0;
                    border-top: 0;
                    border-right: 0;
                    padding-left: 0;
                    max-width: 41.1875rem;
                }
            }
        }
        .btn-close {
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
        }
        &__title {
            font-weight: 700;
            @include text25;
            color: #00003E;
            margin-bottom: 2rem;
        }
        &__list {
            display: grid;
            grid-template-columns: 1.6fr 3fr;
        }
        &__nav {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 0.9375rem;
            button {
                display: flex;
                align-items: center;
                background: transparent;
                width: 100%;
                font-weight: 400;
                @include text14;
                color: #454545;
                position: relative;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 1.625rem;
                    height: 0.4375rem;
                    margin-left: 1.5625rem;
                    opacity: 0;
                    transform: translateX(-0.9375rem);
                    transition: opacity .2s ease, transform .2s ease;
                    background: url('./../../../img/icons/arrow.svg');
                }
                &:hover {
                    &::after {
                        opacity: 1;
                        transform: none;
                    }
                }
                &.active {
                    font-weight: 600;
                }
                img {
                    margin-right: 1.875rem;
                    width: 1.875rem;
                    height: 1.875rem;
                    object-fit: contain;
                }
            }
            &-list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                @include text14;
            }
            &-link {
                margin-bottom: 1.5rem;
            }
        }
    }
}



.easy-autocomplete {
	max-width: 100%;
}
.search-img {
    width: 4.1875rem;
    height: 4.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    position: relative;
    z-index: 1;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(#005EB5, 0.04);
        border-radius: 13px;
        z-index: 1;
    }
	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        position: relative;
    }
}
.search-name {
    flex-grow: 1;
	padding: 0 1.5625rem;
    font-weight: 500;
    @include text14;
    color: #00003E;

}
.search-cost {
    min-width: 5.625rem;
    font-weight: 500;
    @include text14;
    color: #00003E;
}

.eac-item a {
    display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: space-between;
	span {
        display: block;
    }
}
.easy-autocomplete-container {
    background: #FFFFFF;
    overflow: hidden;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.search-head {
    width: 100% !important;
    // max-width: 41.1875rem;
    position: relative;
    @include r($md) {
        max-width: 100% !important;
    }
}
.easy-autocomplete-container {
    margin-top: 0.375rem;
	ul {
        border: 0 !important;
        margin-top: 0.9375rem !important;
        margin-bottom: 0.9375rem !important;
		li {
			padding: 0.3125rem 1.25rem;
            border: 0 !important;
            &:hover {
                .search-name {
                    color: $primary;
                }
                background: $white !important;
            }
        }
    }
}

.mm-spn.mm-spn--navbar:after {
    content: none;
}

.mm-spn.mm-spn--navbar ul {
    top: calc(var(--mm-spn-item-height) * 1.5 + 1px);
}

.mobile-header {
    display: none;
    position: sticky;
    top: 0;
    background: #fff;
    padding: 1.5rem 0 0.5rem;
    z-index: 1000;
    @include r($md) {
        display: block;
    }
    &__grid {
        display: grid;
        grid-template-columns: 2rem 1fr 1fr;
        align-items: center;
        gap: 1.625rem;
    }
    &__toggle svg {
        width: 1.625rem;
        height: 1.625rem;
    }
    .header-logo img {
        max-width: 8.625rem;
    }
    &__search {
        padding: 2rem 0 1rem;
        @include r($sm) {
            padding: 1.25rem 0 0.75rem;
        }
    }
    .header-controls {
        @include r($sm) {
            font-size: 0;
            gap: 1.75rem;
        }
    }
}

.mmenu__header {
    .header-contacts {
        display: flex;
        flex-direction: column;
        gap: 0;
        align-items: center;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        padding: 10px 15px;
    }
    .header-phone {
        padding: 5px 0;
    }
    .dropdown-menu {
        min-width: auto;
        left: auto !important;
    }
}
